import React from 'react'
import Helmet from 'react-helmet'
import {
  Layout,
  Breadcrumbs,
  TextBox,
  QuickFacts,
  BannerLinks,
  BannerSmall,
  CallUs,
  ProjectPagination,
  Image
} from '../../components'

import boxes from '../../components/BannerLinks/DefaultBox'
import contacts from '../../components/CallUs/DefaultContacts'

import { generateBreadcrumbsLinks } from '../../utils/index'

import image4 from '../../assets/bannerlinks/bannerbox.eglo.pim.jpg'
import image5 from '../../assets/bannerlinks/berufsreise.bannerbox.jpg'
import header from '../../assets/header.nht.new.jpg'

export const frontmatter = {
  title: 'Wer hilft Wie',
  slug: '/Projekte/Wer hilft Wie'
}

const Dioezese = () => {
  const links = generateBreadcrumbsLinks('/projekte/nht', 'Neue Heimat Tirol')
  return (
    <Layout margin={false} color="white">
      <Helmet
      title="Neue Heimat Tirol"
      meta={[
          {
          name: 'description',
          content:
              'Für Organisationen, die ihre Geschäftsprozesse optimieren und die Kommunikation sowie den Zugriff auf Informationen innerhalb des Unternehmens erleichtern wollen, gibt es keine bessere Option als die Implementierung eines Intranets, wie das der NHT.'
          },
          {
          name: 'keywords',
          content:
              'nht holzweg, Projekt holzweg, Innsbruck, Webseite holzweg, neue heimat tirol, nht, Heimat'
          },
          {
          property: 'og:description',
          content:
              'Für Organisationen, die ihre Geschäftsprozesse optimieren und die Kommunikation sowie den Zugriff auf Informationen innerhalb des Unternehmens erleichtern wollen, gibt es keine bessere Option als die Implementierung eines Intranets, wie das der NHT.'
          },
          {
            property: 'og:image',
            content: 'https://www.holzweg.com/static/header.nht.new-e9420a1a36b7df1d5d1d4872c2428274.jpg'
          }
      ]}
      />
     <BannerSmall image={header} />
      <Breadcrumbs
        links={links}
        backLinkAlias="Übersicht aller aktuellen Projekte"
      />
      <TextBox
        title={
          <div>
            Neue Heimat Tirol - Intranet
          </div>
        }
        text={
          <span>
            
            <p>
            Für Organisationen, die ihre Geschäftsprozesse optimieren und die Kommunikation sowie den Zugriff auf Informationen innerhalb des Unternehmens erleichtern wollen, gibt es keine bessere Option als die Implementierung eines Intranets.
            </p>
            <p>
            Die Neue Heimat Tirol hat gemeinsam mit holzweg ein Portal geschaffen, um mit modernen Kommunikationskanälen eine gezielte Informations- und Wissensverteilung zu ermöglichen. Als Plattform für Kommunikation, Informationen, Services und Wissensmanagements wird der Dialog zwischen Management und Mitarbeitern, sowie innerhalb einzelner Abteilungen gefördert. Dadurch werden interne Kosten reduziert und die Produktivität der Arbeit kann gesteigert werden.
            Zur weiteren Digitalisierung von Prozessen wurde ein Workflow-System integriert, das gesamte interne Prozesse oder nur einzelne Arbeitsschritte vereinfacht und beschleunigt. Diese Schnittstelle zum Intranet ermöglicht den Mitarbeitern der NHT ein effizientes Zusammenarbeiten. Dadurch können auch personalisierte Inhalte dargestellt werden.
            </p>
            <p>
            Bei der Entwicklung eines Intranets bedarf es auch einer nachhaltigen Umsetzung, um das System mit innovativen Ideen weiterzuentwickeln und für Sicherheit zu sorgen. Dafür ist auch eine schnelle und einfache Wartung der Inhalte nötig. Mit der Verwendung des CMS Typo3 auf Open Source Basis können individuelle Anforderungen umgesetzt werden. Weiters ist dadurch das Intranet flexibel anpassbar, skalierbar und effizient.
            </p>
            <br />
            <i>
            <p>
            "Das Intranet dient als Kommunikations-, Wissens- und Informationsplattform des Unternehmens. Die einfache und übersichtliche Handhabung ermöglicht einen raschen Informationsfluss, die Implementierung von verschiedenen Systemen ermöglicht eine effektive Kommunikation. Holzweg GmbH hat für alle individuellen Ansprüche eine Lösung parat, kompetente Ansprechpersonen machen die Zusammenarbeit einfach."
            </p>
            </i>
            <br />
            <p>
            <b>Christina Fally </b><br />
            Stabsstelle Marketing und Kommunikation
            </p>
          </span>
        }
        
      />
      
      <CallUs contacts={contacts} title="Gerne für Sie da" />
      <TextBox titleMods={["center"]}
        title="Weitere Projekte"
        text={false}
      />
      <ProjectPagination
      titleLeft="Berufsreise"
      imageLeft={image5}
      leftLink="/projekte/berufsreise"
      titleModsLeft={['black']}
      titleRight="EGLO PIM"
      imageRight={image4}
      rightLink="/projekte/eglo-pim"
      />
      <TextBox
        title="Walk with us on the holzweg"
        text="Ready für uns? Dann kontaktiere uns zu einem unverbindlichen Beratungsgespräch oder verwende unsere Call-Back Funktion. Wenn der holzweg nach Traumjob-Garantie klingt, dann schaue bei den Stellenangeboten rein oder besuche uns in den sozialen Medien!"
      />
      <BannerLinks boxes={boxes} />
    </Layout>
  )
}

export default Dioezese
